import React from "react";

const Contact = () => {
  return (
    <div class="u-body u-xl-mode">
      <header
        class="u-clearfix u-header u-palette-2-base u-header"
        id="sec-2485"
      >
        <div class="u-clearfix u-sheet u-sheet-1">
          <p class="u-custom-font u-text u-text-default u-text-1">LUKIRAN</p>
        </div>
      </header>
      <footer
        class="u-align-center u-clearfix u-footer u-grey-80 u-footer"
        id="sec-2692"
      >
        <div class="u-clearfix u-sheet u-sheet-1">
          <p class="u-small-text u-text u-text-variant u-text-1">
            {" "}
            2023 LukiranRights Reserved. Designed By&nbsp;
            <a
              href="https://themetags.com/"
              class="text-decoration-none u-active-none u-border-none u-btn u-button-style u-hover-none u-none u-text-palette-1-base u-btn-1"
            >
              L
            </a>
            ukiran
          </p>
        </div>
      </footer>
      <section class="u-backlink u-clearfix u-grey-80">
        <a
          class="u-link"
          href="https://nicepage.com/website-templates"
          target="_blank"
        >
          <span>Website Templates</span>
        </a>
        <p class="u-text">
          <span>created with</span>
        </p>
        <a class="u-link" href="" target="_blank">
          <span>Website Builder Software</span>
        </a>
        .
      </section>
    </div>
  );
};

export default Contact;
