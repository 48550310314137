import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import "./assets/styles/About.css";
import "./assets/styles/Contact.css";
import "./assets/styles/Home.css";
import "./assets/styles/nicepage.css";

const Application = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/contact">
          <About />
        </Route>
        <Route path="/about-us">
          <Contact />
        </Route>
      </Switch>
    </Router>
  );
};

ReactDOM.render(<Application />, document.getElementById("root"));
